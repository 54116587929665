import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, map, Observable, Subject, takeUntil, tap } from 'rxjs';

import { AuthAbstractService } from 'src/app/modules/core/services/auth/auth-abstract.service';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { User } from 'src/app/modules/core/models/user';
import { UserManagementService } from 'src/app/modules/admin/users/services/user-management/user-management.service';
import { VAUserPermission, VAUserRole, VAUserService } from 'src/app/modules/vaitsn/services/va-user.service';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-app-layout',
  templateUrl: './app-layout.component.html',
  styleUrls: ['./app-layout.component.scss'],
})
export class AppLayoutComponent implements OnInit, OnDestroy {
  VAUserRole = VAUserRole;
  private _subscribedSubjects$ = new Subject<boolean>();
  public currentDate: Date = new Date();
  public user$: Observable<User | null>;
  userList: User[];
  userId: string = null;
  selectedUser: User;
  public externalLogin: boolean = false;
  private readonly _destroying$ = new Subject<void>();
  public collapsedMenu = false;
  public userName: String;
  public loadingPermissions = true;
  public VERSION = environment.version;

  public menuItems = [
    { url: '/app/dashboard', name: 'Dashboard', icon: 'home', adminOnly: false, visibleTo: [VAUserPermission.Consultant, VAUserPermission.Specialist] },
    { url: '/app/travel', name: 'Travel', icon: 'card_travel', adminOnly: false, visibleTo: [VAUserPermission.Consultant, VAUserPermission.Specialist] },
    { url: '/app/asq', name: 'ASQ3', icon: 'description', adminOnly: false, visibleTo: [VAUserPermission.Consultant]},
    { url: '/app/asqse', name: 'ASQSE', icon: 'description', adminOnly: false, visibleTo: [VAUserPermission.Consultant]},
    { url: '/app/child-specific-scale', name: 'Child Specific Scale', icon: 'description', adminOnly: false, visibleTo: [VAUserPermission.Consultant] },
    { url: '/app/child-referral', name: 'Child Referral', icon: 'description', adminOnly: false, visibleTo: [VAUserPermission.Consultant] },
    { url: '/app/class-scores', name: 'CLASS Scores', icon: 'description', adminOnly: false, visibleTo: [VAUserPermission.Consultant, VAUserPermission.Specialist] },
    { url: '/app/m-chat-r', name: 'M-Chat-R', icon: 'description', adminOnly: false, visibleTo: [VAUserPermission.Consultant] },
    { url: '/app/piccolo-scores', name: 'Piccolo Scores', icon: 'description', adminOnly: false, visibleTo: [VAUserPermission.Consultant] },
    { url: '/app/tpitos', name: 'TPITOS', icon: 'description', adminOnly: false, visibleTo: [VAUserPermission.Consultant] },
    { url: '/app/class-environment-score', name: 'CLASS Environment Score', icon: 'description', adminOnly: false, visibleTo: [VAUserPermission.Specialist]},
    { url: '/app/reports', name: 'Reports', icon: 'insert_chart_outlined', adminOnly: false, visibleTo: [VAUserPermission.Consultant, VAUserPermission.Specialist] },
    { url: '/app/users', name: 'Users', icon: 'supervisor_account', adminOnly: true, visibleTo: []},
  ];
  public filteredMenuItems : {url:string,name:string,icon:string,adminOnly:boolean,visibleTo:VAUserPermission[]}[];

  constructor(
    private authSvc: AuthAbstractService,
    private userSvc: UserService,
    private userMgmtService: UserManagementService,
    public vaUserService: VAUserService
  ) { }

  ngOnInit(): void {
    this.user$ = this.userSvc.user$;
    this.vaUserService.currentAssociatedVAUser$.pipe(
      filter(u => u !== null),
      takeUntil(this._subscribedSubjects$),
      map((vaUser) => {

        if(!vaUser.vaUserRoles.some(
            r => (r.role.normalizedName == VAUserRole.Admin || r.role.normalizedName == VAUserRole.Supervisor)
        )){
            this.filteredMenuItems = this.menuItems.filter(
                menuItem =>  menuItem.visibleTo.some(
                    m => vaUser.vaUserPermissions.some(p => p.permission.normalizedName == m)
                )
            );
        } else {
            this.filteredMenuItems = this.menuItems;
        }
        
        this.userName = vaUser.username;
        this.userId = vaUser.id;
        this.loadingPermissions = false;
      })).subscribe();
  }

  getUsername(name: String) {
    return name.substring(0, name.indexOf('@'));
  }

  toggleMenu() {
    this.collapsedMenu = !this.collapsedMenu;
  }

  logout() {
    this.authSvc.logout(true).subscribe();
  }

  clearClicked() {
    this.userId = null;
  }

  ngOnDestroy(): void {
    this._subscribedSubjects$.next(true);
    this._subscribedSubjects$.complete();
  }
}
