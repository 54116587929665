import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Observable, Subject, debounceTime, distinctUntilChanged, switchMap } from 'rxjs';
import ODataFilterBuilder from 'odata-filter-builder';

import {
  IClassroom,
  IParamObj,
  IProgram,
  IProgramClassroomSearchQuery,
} from '../../models';
import { ClassroomService } from '../../services/classroom.service';

/** 
 * Odata query of Classroom table with associated Program expanded 
 * instead of querying LookupAssociatedClassProgramChild table
 * */
@Component({
  selector: 'app-search-program-classroom-odata',
  templateUrl: './search-program-classroom-odata.component.html',
  styleUrls: ['./search-program-classroom-odata.component.scss']
})
export class SearchProgramClassroomODataComponent implements OnInit, OnChanges {
  @Input() currentProgram: IProgram = null;
  @Input() currentClassroom: IClassroom = null;
  @Input() regionFilter: number = null;
  @Output() select$ = new EventEmitter<IClassroom>();

  public isNotSearching: boolean = true;
  public searchResults$: Observable<Array<IClassroom>>;
  private searchText$ = new Subject<string>();
  private oDataFilter = ODataFilterBuilder();
  public searchQuery: IProgramClassroomSearchQuery;

  constructor(
    private classroomService: ClassroomService
  ) { }

  ngOnInit() {
    this.getSearchResults();
  }

  /**
   * When change coach/region in parent component,
   * clear selected prog/class (switching regions so no longer valid selections)
   * and if searching, update region search filter/results
   * emit change to form
   * @param changes 
   */
  ngOnChanges(changes: SimpleChanges) {
    if (changes['regionFilter'] != null && !changes['regionFilter'].firstChange) {
      this.currentProgram = null;
      this.currentClassroom = null;
      this.select$.emit(null);

      if (!this.isNotSearching) {
        this.searchQuery.programRegionId = this.regionFilter;
        this.updateSearch(null);
      }
    }
  }

  onSelect(selectedSearchResult: IClassroom) {
    this.currentProgram = selectedSearchResult.program;
    this.currentClassroom = selectedSearchResult;
    this.isNotSearching = true;
    this.select$.emit(selectedSearchResult);
  }

  public getEventValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  public updateSearch(searchString: string) {
    this.buildFilter();
    this.searchText$.next(searchString);
  }

  public initSearchFilters() {
    this.isNotSearching = false;
    this.searchQuery = {
      programRegionId: this.regionFilter,
      programCode: '',
      programName: '',
      programAddress: '',
      classroomName: '',
    };
  }

  private buildFilter() {
    this.oDataFilter = ODataFilterBuilder("and")
      .contains('name', this.searchQuery.classroomName) // classroom
      .contains('Program/code', this.searchQuery.programCode)
      .contains('Program/name', this.searchQuery.programName)
      .contains('Program/address', this.searchQuery.programAddress);

    this.searchQuery.programRegionId && this.oDataFilter.eq('Program/regionId', this.searchQuery.programRegionId);
  }

  /**
   * Get search results from odata Classrooms entity w/Program expanded
   * Filter based on provided search parameters
   * Return top 8 results, ordered by Program.Code ASC, then by Classroom.Id ASC
   */
  private getSearchResults() {
    this.searchResults$ = this.searchText$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(() => {
        const params = { 
          $expand: 'Program',
          $filter: this.oDataFilter.toString(),
          $orderby: 'Program/code asc, id asc',
          $top: 8 
        } as IParamObj;
        
        return this.classroomService.get(params);
      })
    );
  }
}
