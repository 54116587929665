import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, combineLatest, debounceTime, distinctUntilChanged, filter, map, switchMap, takeUntil, tap } from 'rxjs';
import { ODataFilterBuilder } from 'odata-filter-builder';
const { DateTime } = require("luxon");

import {
  GUID,
  IChild,
  IClassroom,
  IEventType,
  ILookupAssociatedClassProgramChild,
  IParamObj,
  IProgram,
  IRegion,
  IVAUser,
} from 'src/app/modules/vaitsn/models';
import { UserService } from 'src/app/modules/core/services/user/user.service';
import { RegionService } from '../../../services/region.service';
import { ChildService } from '../../../services/child.service';
import { ClassroomService } from '../../../services/classroom.service';
import { EventService } from '../../../services/event.service';
import { VAUserPermission, VAUserRole, VAUserService } from '../../../services/va-user.service';
import { EventTypeService } from '../../../services/event-type.service';
import { ToastService } from 'src/app/modules/core/services/toast/toast.service';
import { EventType, VAConstants } from '../../../vaitsn-constants';

/**
 * Custom cross-validator for entire form.
 * Checks compatibility and dynamic requirement of 
 * event type + location type + program type + prog/class/otherclass values
 * @param control `this.eventDetailForm` (FormGroup)
 * @returns `'invalid<type>'` validation error if error. Else, null (FormGroup is valid).
 */
export const formValidator: ValidatorFn = (
  control: AbstractControl, // this.eventCoachingForm - FormGroup
): ValidationErrors | null => {
  let formGroup = control as FormGroup;

  const eventType = formGroup?.get('EventType');
  const isLocationProgram = formGroup?.get('isLocationProgram');
  const isProgramVqb5 = formGroup?.get('isProgramVqb5');
  const program = formGroup?.get('Program');
  const classroom = formGroup?.get('Classroom');
  const otherClassroom = formGroup?.get('otherClassroom');

  // if event type not selected yet, skip check
  if(eventType.invalid || !eventType.value) {
    //console.debug('skipping location data check until event type set and valid');
    return null;
  }

  // once have event type, check for valid combo of event type + location data

  //console.debug('event type valid ', eventType.value);
  if(eventType.value.id === EventType.TechnicalAssistance) {
    let taIsValid = 
      // haven't touched program type yet
      (isProgramVqb5.pristine && isProgramVqb5.untouched)
      // have set
      || (
        (
          // or set to vqb5 and
          // prog/class not touched yet
          // or touched and not null
          (
            isProgramVqb5.value === true 
            && (otherClassroom.value === null || otherClassroom.value === "")
            && (
              (program.pristine && program.untouched && classroom.pristine && classroom.untouched)
              || (program.value !== null && classroom.value !== null)
            )
          )
          // or set to non-vqb5 and
          // prog + other class not touched yet
          // or touched and not null
          || (
            isProgramVqb5.value === false 
            && classroom.value === null
            && (
              (program.pristine && program.untouched && otherClassroom.pristine && otherClassroom.untouched)
              || (program.value !== null && (otherClassroom.value !== null && otherClassroom.value !== ""))
            )
          )
        )
        && isLocationProgram.value === true // always true for TA events
      );

    //console.debug('TA valid ? ', taIsValid);
    return taIsValid ? null : { invalidTA: true };
  }
  else if(eventType.value.isTrng === true){
    let trngIsValid = 
      // haven't touched location type yet
      (isLocationProgram.pristine && isLocationProgram.untouched)
      // have set location type
      || (
        (
          // if location type set to other + everything else null
          (
            isLocationProgram.value === false
            && isProgramVqb5.value === null && program.value === null && classroom.value === null
          )
          // or set location type set to program and
          // prog/class not touched yet
          // or touched and not null
          || (isLocationProgram.value === true 
            && isProgramVqb5.value === true
            && (
              (program.pristine && program.untouched && classroom.pristine && classroom.untouched)
              || (program.value !== null && classroom.value !== null)
            )
          )
        )
        && (otherClassroom.value === null || otherClassroom.value === "") // always blank
      );

    //console.debug('Trng valid ? ', trngIsValid);
    return trngIsValid ? null : { invalidTrng: true };
  }
  else if(eventType.value.isTrng === false){
    let coachingIsValid = 
      isLocationProgram.value === true && isProgramVqb5.value === true && (otherClassroom.value === null || otherClassroom.value === "")
      && (
        (program.pristine && program.untouched && classroom.pristine && classroom.untouched)
        || (program.value !== null && classroom.value !== null)
      );

    //console.debug('coaching valid ? ', coachingIsValid);
    return coachingIsValid ? null : { invalidCoaching: true };
  }
  else {
    return null;
  }
};

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.scss']
})
export class EventDetailsComponent implements OnInit, OnDestroy {
  VAUserRole = VAUserRole;
  public eventDetailForm: FormGroup = null;
  private _subscribedSubjects$ = new Subject<boolean>();
  public _currentVAUser: IVAUser = null;
  public mainPresenter: IVAUser = null;
  public currentEventId: number = 0;
  public isFormLoading: boolean = true;
  public currentProgram: IProgram = null
  public currentClassroom: IClassroom = null;
  public regions: Array<IRegion> = [];
  public filteredRegions: Array<IRegion> = [];
  public eventTypes: Array<IEventType> = [];
  public filteredEventTypes: Array<IEventType> = [];
  public children$!: Observable<Array<IChild>>;
  private childSearchText$ = new Subject<string>();
  public classrooms$!: Observable<Array<IClassroom>>;
  private classroomSearchText$ = new Subject<string>();
  private childIdRequired = false;
  public minDate = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
  public maxDate = new Date(new Date());
  //public locationTypeOptions = VAConstants.EVENT_LOCATION_TYPES;
  public regionFilter: number = null;
  public showChildField = false;
  public showLocationTypeField = false;
  public showIsProgramVqb5Field = false;

  get isLocationProgram() {
    return this.eventDetailForm.get('isLocationProgram').value;
  }

  get isProgramVqb5() {
    return this.eventDetailForm.get('isProgramVqb5').value;
  }

  constructor(
    public userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private regionService: RegionService,
    private childService: ChildService,
    private classroomService: ClassroomService,
    private eventService: EventService,
    public vaUserService: VAUserService,
    private eventTypeService: EventTypeService,
    private toast: ToastService,
  ) { }

  /**
   * @todo Improve get required data before finish loading
   */
  public ngOnInit() {
    this.currentEventId = +this.activatedRoute.snapshot.paramMap.get('eventId');

    // Get regions first because required to get current VAuser info
    // then can get rest of data
    this.getRegions().subscribe({
      next: () => {
        this.getRequiredData().subscribe({
          next: (_) => {
            this.getChildren();
            this.getClassrooms();
            this.classroomSearch('');

            this.initForm();
          },
          error: err => {
            console.error(err);
            this.handlePageLoadError('Error getting required page data');
          }
        });
      },
      error: err => {
        console.error(err);
        this.handlePageLoadError('Error getting region data');
      }
    });
  }

  /**
   * Notify user of error
   * Return to previous step
   * @param err 
   */
  private handlePageLoadError(err?: string) {
    err && console.error(err);
    this.toast.error("Error loading form. Please refresh page and try again.");
  }

  /**
   * Get required data for form from services
   * 1: Get current VAUser
   * 2: Get Event Types filtered by coach's type
   * @returns 
   */
  private getRequiredData = () => {
    let sources = [];

    sources.push(this.getVAUserDetail());
    sources.push(this.getEventTypes());

    return combineLatest(sources);
  }

  //region must run first, getVAUserDetail (non admin user) relies on region data
  private getRegions() {
    return this.regionService.get().pipe(
      takeUntil(this._subscribedSubjects$),
      map((regions) => {
        this.regions = regions;
        this.filteredRegions = regions;
      }));
  }

  /**
   * Get logged int user's data and save to `_currentVAUser`
   */
  private getVAUserDetail() {
    return this.vaUserService.currentAssociatedVAUser$.pipe(
      filter(c => c !== null),
      takeUntil(this._subscribedSubjects$),
      map((vaUser) => {
        this._currentVAUser = vaUser;
      })
    );
  }

  /**
   * Filter to non-deleted Event Types
   * Display order based on custom `sortOrder` column
   */
  private getEventTypes() {
    return this.eventTypeService
      .get({ $orderby: 'sortOrder' } as IParamObj)
      .pipe(
        takeUntil(this._subscribedSubjects$),
        map((eventTypes) => {
          this.eventTypes = eventTypes;
          this.filteredEventTypes = eventTypes;
        }));
  }

  private getChildren() {
    this.children$ = this.childSearchText$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(searchString => {
        let filters = ODataFilterBuilder('and').contains('code', searchString);
        if(this.regionFilter !== null) filters.eq('regionId', this.regionFilter);
        const params = { 
          $filter: filters.toString(), 
          $top: 8 
        } as IParamObj;
        return this.childService.get(params)
      })
    );
  }

  private getClassrooms() {
    this.classrooms$ = this.classroomSearchText$.pipe(
      debounceTime(500),
      distinctUntilChanged(),
      switchMap(searchString => {
        const filters = { $filter: ODataFilterBuilder().contains('name', searchString).toString(), $top: 5 } as IParamObj;
        return this.classroomService.get({ $filter: filters, $top: 5 } as IParamObj)
      })
    );
  }

  /**
   * Init form with default values
   * Then perform additional checks to update form based on current user / event data
   * If current user is not an admin and they are creating:
   * - auto-set region to user's region
   * - filter program/class lookup to user's region
   * - and show/hide child field based on if they do/don't have consultant permission
   * Else if editing: update form with current event's data
   * @todo improve logic for create vs edit flows
   * @todo and set main presenter = current user ?
   * @todo User should be part of form ?
   */
  private initForm() {
    this.eventDetailForm = new FormGroup(
      {
        Region: new FormControl(null, [Validators.required]),
        eventDate: new FormControl(null, [Validators.required]),
        Length: new FormControl(null, [Validators.required]),
        EventType: new FormControl(null, [Validators.required]),
        Child: new FormControl({ value: null, disabled: true }),
        isLocationProgram: new FormControl(null, [Validators.required]),
        isProgramVqb5: new FormControl(null),
        Program: new FormControl(null),
        Classroom: new FormControl(null),
        otherClassroom: new FormControl(null),
        IsRemote: new FormControl(false, [Validators.required]),
      },
      { validators: formValidator }
    );

    if (!this.vaUserService.userHasRole(this._currentVAUser, VAUserRole.Admin) && this.currentEventId <= 0) {
      this.eventDetailForm.patchValue({ Region: this.regions.find(x => x.id == this._currentVAUser?.regionId) });
      this.regionFilter = this._currentVAUser?.regionId;
      this.showChildField = this.vaUserService.userHasPermission(this._currentVAUser, VAUserPermission.Consultant);
      this.filterEventTypesBySearch('');
    }

    if (this.currentEventId > 0 && !this.eventService.$currentEvent?.value?.event?.regionId) {
      this.eventService.refreshCurrentEvent(this.currentEventId).pipe(
        takeUntil(this._subscribedSubjects$),
        tap((_) => {
          this.getFormFieldsFromCurrentEventObj();
        })).subscribe({
          error: (e) => {
            console.error(e);
            this.router.navigate(['app/dashboard']);
          }
        });
    } else {
      if (this.eventService.$currentEvent?.value?.event?.regionId) {
        this.getFormFieldsFromCurrentEventObj();
      } else {
        this.isFormLoading = false;
      }
    }
  }

  /**
   * Edit workflow
   * Patch form and other component variables from currentEvent data
   * Re-evaluate if child input should be required/editable
   */
  private getFormFieldsFromCurrentEventObj() {
    const eventObj = this.eventService.$currentEvent.value;
    this.eventDetailForm.patchValue({
      Region: eventObj.eventRegion,
      eventDate: eventObj.event.eventDate,
      EventType: eventObj.eventType,
      Child: eventObj.eventChild,
      Program: eventObj.eventProgram,
      Classroom: eventObj.eventClassroom,
      Length: eventObj.event.length,
      IsRemote: eventObj.event.isRemote,
      isLocationProgram: eventObj.event.isLocationProgram,
      isProgramVqb5: eventObj.eventType.id == EventType.TechnicalAssistance && eventObj.eventClassroom !== null 
        ? true 
        : eventObj.eventType.id == EventType.TechnicalAssistance && eventObj.eventClassroom === null
          ? false
          : eventObj.eventType.id != EventType.TechnicalAssistance && eventObj.eventProgram != null
            ? true
            : null,
      otherClassroom: eventObj.event.otherClassroom
    });

    this.regionFilter = eventObj.eventRegion?.id;

    this.currentProgram = eventObj.eventProgram;
    this.currentClassroom = eventObj.eventClassroom;
    this.regionFilter = eventObj.eventRegion?.id;

    this.onEventTypeChanged(null);
    this.getMainPresenterVaUser(eventObj.event.mainPresenterId);
  }

  private getMainPresenterVaUser(mainId: string) {
    const builder = ODataFilterBuilder('and').eq('Id', new GUID(mainId));
    const params = {
      $filter: builder.toString(),
      $expand: 'VAUserRoles($expand=Role), VAUserPermissions($expand=Permission)',
      $top: 1
    } as IParamObj;
    this.vaUserService.get(params).pipe(
      map((res) => {
        this.mainPresenter = res[0];

        this.setChildFieldVisibility();
        this.filterEventTypesBySearch('');

        this.isFormLoading = false;
      })
    ).subscribe();
  }

  
  /**
   * Based on current user's role and the selected main presenter (may be same), show/hide Child field
   * if current user is admin and/or selected main presenter is a consultant, show 
   * @todo should only be based on selected presenter (if not set yet, show)
   */
  private setChildFieldVisibility = (): void => {
    let eventCreatorIsAdmin = this.vaUserService.userHasRole(this._currentVAUser, VAUserRole.Admin);

    if (eventCreatorIsAdmin) {
      this.showChildField = this.vaUserService.userHasPermission(this.mainPresenter, VAUserPermission.Consultant);
    } else {
      this.showChildField = this.vaUserService.userHasPermission(this._currentVAUser, VAUserPermission.Consultant);
    }
  }

  /**
   * Filter `filteredEventTypes` list based on user's search input
   * Also if Event Type is already selecte
   * @param searchString 
   */
  public filterEventTypesBySearch(searchString: string) {
    let showChildCoaching: boolean;
    let eventCreatorIsAdmin = this.vaUserService.userHasRole(this._currentVAUser, VAUserRole.Admin);

    if (eventCreatorIsAdmin) {
      showChildCoaching = this.mainPresenter == null || this.vaUserService.userHasPermission(this.mainPresenter, VAUserPermission.Consultant);
    } else {
      showChildCoaching = this.vaUserService.userHasPermission(this._currentVAUser, VAUserPermission.Consultant);
    }

    this.filteredEventTypes = this.eventTypes.filter(eventType => {
      return eventType.name.includes(searchString) && (eventType.id != EventType.ChildCoaching || showChildCoaching)
    });
  }

  /**
   * Filter `filteredEventTypes` based on selected coach's permission type
   * -Remove Child Coaching if selected coach is not a consultant
   */
  public filterEventTypesByPerms = (id: number) => {
    if (id != EventType.ChildCoaching) {
      return true; // can show any that are not child coaching
    }
    else if (this.mainPresenter != null && !this.vaUserService.userHasPermission(this.mainPresenter, VAUserPermission.Consultant)) {
      return false; // coach has been selected but they are not a consultant
    }
    else {
      return true; // coach not selected yet or coach is a consultant
    }
  }

  /**
   * If selected child coaching and newly selected main presenter != consultant,
   * clear event type-related data including prog/class info
   */
  private clearEventType = () => {
    if (this.eventDetailForm.value.EventType?.id == EventType.ChildCoaching
      && this.mainPresenter != null
      && !this.vaUserService.userHasPermission(this.mainPresenter, VAUserPermission.Consultant)
    ) {
      this.eventDetailForm.patchValue({ 
        EventType: null,
        isLocationProgram: null,
        isProgramVqb5: null
      });
      this.eventDetailForm.controls['Program'].reset();
      this.eventDetailForm.controls['Classroom'].reset();
      this.showLocationTypeField = false;
      this.showIsProgramVqb5Field = false;
    }
  }

  /**
   * When Event Type is changed (set during init, searching, another option clicked),
   * 1) re-evaluate is Child should be enabled/required
   * - Only enable/require "Child" field if event type is "Child Coaching" or "Technical Assistance"/"TA" 
   * - for child coaching it is required
   * - for TA it should show but not be required
   * 2) If select non-child event types, clear child data
   * 3) if training event, show Location Type field, else hide/auto-set isLocationProgram = true
   * 4) if coaching event EXCEPT TA, hide Location Type and show prog/class fields
   * 5) if TA event, hide location type and show isvqb5 (+ prog/class fields only if isvqb5 = true)
   * @param event 
   */
  public onEventTypeChanged(event: Event) {
    const currentEventType = this.eventDetailForm.value.EventType;

    if (currentEventType?.isTrng) {
      //console.debug('Curr event type trng group');
      this.showLocationTypeField = true;
      this.showIsProgramVqb5Field = false;
      if(event != null) this.eventDetailForm.patchValue({ isLocationProgram: null, isProgramVqb5: null });
    } else if (currentEventType?.id === EventType.TechnicalAssistance) {
      //console.debug('Curr event type = tech assist');
      this.showLocationTypeField = false;
      this.showIsProgramVqb5Field = true;
      if(event != null) this.eventDetailForm.patchValue({ isLocationProgram: true, isProgramVqb5: null });
    } else { // Coaching (except TA)
      this.showLocationTypeField = false;
      this.showIsProgramVqb5Field = false;
      if(event != null) this.eventDetailForm.patchValue({ isLocationProgram: true, isProgramVqb5: true });
    }

    if (currentEventType?.id != EventType.ChildCoaching
      && currentEventType?.id != EventType.TechnicalAssistance
      && currentEventType?.id !== EventType.ChildConclusionVisit) {
      this.childIdRequired = false;
      this.eventDetailForm.controls['Child'].reset();
      this.eventDetailForm.controls['Child'].disable();
    }
    else {
      this.childIdRequired = (currentEventType?.id === EventType.ChildCoaching || 
                              currentEventType?.id === EventType.ChildConclusionVisit);
      this.eventDetailForm.controls['Child'].enable();
    }
  }

  public onRegionChanged(event: Event) {
    this.regionFilter = this.eventDetailForm.value.Region?.id;
  }

  //admin sets the region for the select user that the admin selected
  public selectedMainPresenter(value: IVAUser) {
    this.mainPresenter = value;
    this.eventDetailForm.patchValue({ Region: this.regions.find(x => x.id == value.regionId) });
    this.filterEventTypesBySearch('');
    this.clearEventType();
    this.regionFilter = value.regionId;
    this.showChildField = this.vaUserService.userHasPermission(value, VAUserPermission.Consultant);
  }

  /**
   * Reset location-related fields when switching location type
   * @param _event 
   */
  public isLocationProgramChanged = (event: any) => {
    //console.debug('isLocationProgramChanged() ', event);
    this.eventDetailForm.patchValue({ isProgramVqb5: event.value === true || null });
    this.eventDetailForm.controls['Program'].reset();
    this.eventDetailForm.controls['Classroom'].reset();
  }

  /**
   * Reset location-related fields when switching vqb5
   * @param _event 
   */
  public isProgramVqb5Changed = (event: any) => {
    //console.debug('isProgramVqb5 changed: ', event);
    this.eventDetailForm.controls['Program'].reset();
    this.eventDetailForm.controls['Classroom'].reset();
    this.eventDetailForm.controls['otherClassroom'].reset();
  }

  public eventTypeDisplay = (eventType: IEventType): string => eventType?.name;

  public getEventValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  public filterRegions(searchString: string) {
    this.filteredRegions = this.regions.filter(region => region.name.includes(searchString) || region.code.includes(searchString))
  }

  public regionDisplay = (region: IRegion): string => region?.name;

  public childSearch(searchString: string) {
    this.childSearchText$.next(searchString);
  }

  public childDisplay = (child: IChild): string | null => child?.code;

  public classroomSearch(searchString: string) {
    this.classroomSearchText$.next(searchString);
  }

  public classroomDisplay = (classroom: IClassroom): string => classroom?.name;

  /**
   * When `app-search-program-odata` component outputs selection,
   * set selected non-vqb5 Program value in this form
   * @param value 
   */
  public selectedProgram(value: IProgram) {
    this.eventDetailForm.patchValue({
      Program: value,
    });
  }

  /**
   * When `app-search-program-classroom-odata` component outputs selection,
   * set selected { Classroom + associated Program } values in this form
   */
  public selectedProgramClassroom(value: IClassroom) {
    this.eventDetailForm.patchValue({
      Program: value?.program ?? null,
      Classroom: value,
    });
  }

  /**
   * Return true if input is not clean and is invalid
   * For Child: return true if input is not clean and child is required + falsey (including being a search string)
   * @param formControlName 
   * @returns 
   */
  public isInputInvalid = (formControlName: string): boolean => {
    let control = this.eventDetailForm.controls[formControlName];

    if (formControlName === 'Child') {
      return (control.dirty || control.touched)
        && this.childIdRequired
        && (!control.value || control.value?.id === undefined);
    }

    return control.invalid
      && (control.dirty || control.touched);
  }

  /**
   * @todo Improve child validation and main presenter validation
   */
  public onSubmit() {
    this.eventDetailForm.markAllAsTouched();
    this.eventDetailForm.updateValueAndValidity();
    console.debug(this.eventDetailForm.value);

    if (this.eventDetailForm.invalid || this.isInputInvalid('Child')) {
      return;
    }

    this.setFormFieldsToCurrentEventObj();
    this.dynamicNextStep();
  }

  /**
   * Create/Edit workflow (submit)
   */
  private setFormFieldsToCurrentEventObj() {
    let eventObj = this.eventService.$currentEvent.value;
    eventObj.event.mainPresenterId = (this.mainPresenter) ? this.mainPresenter.id : this._currentVAUser.id;
    const convertedEventDate = (DateTime.fromISO(this.eventDetailForm.value.eventDate).toFormat('yyyy-MM-dd') === 'Invalid DateTime')
      ? DateTime.fromJSDate(this.eventDetailForm.value.eventDate)
      : DateTime.fromISO(this.eventDetailForm.value.eventDate);

    eventObj.event.regionId = this.eventDetailForm.value.Region.id;
    eventObj.eventRegion = this.eventDetailForm.value.Region;
    eventObj.event.eventDate = convertedEventDate.toFormat("yyyy-MM-dd'T00:00:00'");
    eventObj.event.typeId = this.eventDetailForm.value.EventType.id;
    eventObj.eventType = this.eventDetailForm.value.EventType;
    eventObj.event.childId = this.eventDetailForm.value.Child && this.eventDetailForm.value.Child.id;
    eventObj.eventChild = this.eventDetailForm.value.Child;
    eventObj.event.programId = this.eventDetailForm.value.Program && this.eventDetailForm.value.Program.id;
    eventObj.eventProgram = this.eventDetailForm.value.Program;
    eventObj.event.classroomId = this.eventDetailForm.value.Classroom && this.eventDetailForm.value.Classroom.id;
    eventObj.eventClassroom = this.eventDetailForm.value.Classroom;
    eventObj.event.length = this.eventDetailForm.value.Length;
    eventObj.event.isRemote = this.eventDetailForm.value.IsRemote;
    eventObj.event.isLocationProgram = this.eventDetailForm.value.isLocationProgram;
    eventObj.event.isProgramVqb5 = this.eventDetailForm.value.isProgramVqb5;
    eventObj.event.otherClassroom = this.eventDetailForm.value.otherClassroom === "" ? null : this.eventDetailForm.value.otherClassroom;

    if (this.currentEventId <= 0) {
      eventObj.event.created = DateTime.now().toFormat('yyyy-MM-dd');
      eventObj.event.createdByUser = this._currentVAUser.username;
      // eventObj.eventDetail.created = new Date().toISOString();
      // eventObj.eventDetail.createdByUser = this._currentVAUser.username;
      // eventObj.eventDetail.lastUpdated = new Date().toISOString();
      // eventObj.eventDetail.lastUpdatedUser = this._currentVAUser.username;
    }
    eventObj.event.lastUpdated = DateTime.now().toFormat('yyyy-MM-dd');
    eventObj.event.lastUpdatedUser = this._currentVAUser.username;

    this.eventService.$currentEvent.next(eventObj);
  }

  /**
   * Event wizard steps are dynamic based on Event Type selected.
   * - Coaching-type events: Event Details <=> Coaching/TA <=> Participants => submit.
   * - Training-type events: Event Details <=> Training <=> Participants => submit.
   * - Other events (Marketing/Networking): Event Details => submit.
   */
  dynamicNextStep = () => {
    let eventObj = this.eventService.$currentEvent.value;
    const eventType = this.eventTypes.find(et => eventObj.event.typeId == et.id);

    if (eventType === undefined) { console.warn('Unable to determine next step.'); }
    else if (eventType.isTrng)
      this.router.navigate(['app/event/training/', this.currentEventId]);
    else if (!['Marketing Activities', 'Networking'].includes(eventType?.name)) // Coaching event types
      this.router.navigate(['app/event/coaching/', this.currentEventId]);
    else { // Other event types like marketing don't show other steps
      this.finalizeEvent();

      if (this.currentEventId === 0) {
        this.eventService.post().pipe(tap((_) => {
          this.router.navigate(['app/dashboard']);
        })).subscribe();
      } else {
        this.eventService.update().pipe(tap((_) => {
          this.router.navigate(['app/dashboard']);
        })).subscribe();
      }
    }
  }

  /**
   * Prep data for JSON request, including defaults for non-nullable fields not required in forms and metadata.
   * Overwrite data in shared Event Service one final time.
   */
  private finalizeEvent() {
    let eventObj = this.eventService.$currentEvent.value;

    if (eventObj.event.childId == 0 || !eventObj.event.childId) eventObj.event.childId = null;

    // eventObj.eventDetail.created = new Date().toISOString();
    // eventObj.eventDetail.createdByUser = this._currentVAUser.username;
    // eventObj.eventDetail.lastUpdated = new Date().toISOString();
    // eventObj.eventDetail.lastUpdatedUser = this._currentVAUser.username;

    this.eventService.$currentEvent.next(eventObj);
  }

  ngOnDestroy() {
    this._subscribedSubjects$.next(true);
    this._subscribedSubjects$.complete();
  }
}

