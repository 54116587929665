export interface IProgramSearchQuery {
  region?: number | null,
  code?: string;
  name?: string;
  address?: string;
  classroom?: string;
  created?: string;
};

export interface IProgramClassroomSearchQuery {
  programRegionId?: number | null,
  programCode?: string;
  programName?: string;
  programAddress?: string;
  classroomName?: string;
};
