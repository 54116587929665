<div *ngIf="isNotSearching; else isSearching" class="program-class-not-searching-grid">
    <div class="program-class-col">
        <vaitsn-button color="primary" (click)="initSearchFilters()" size="square" customWidth="100%"
            customHeight="45px">
            <mat-icon class="search-icon" aria-hidden="false" aria-label="search" fontIcon="search"></mat-icon>
        </vaitsn-button>
    </div>
    <div class="program-class-col-v2">
        <mat-form-field appearance="outline" class="vaitsn-text-field">
            <input type="text" class="va-input-field" matInput placeholder="Program"
                [value]="(currentProgram == null ? '' : currentProgram?.code + ' | ' + currentProgram?.name)" readonly>
        </mat-form-field>
    </div>
    <div class="program-class-col-v2">
        <mat-form-field appearance="outline" class="vaitsn-text-field">
            <input type="text" matInput placeholder="Classroom" 
            [value]="(currentClassroom == null ? '' : currentClassroom?.id + ' | ' + currentClassroom?.name)" readonly>
        </mat-form-field>
    </div>
</div>
<ng-template #isSearching>
    <div class="program-search-border-box">
        <div class="program-class-grid">
            <div>
                <a>
                    <mat-icon class="cancel-icon" aria-hidden="false" aria-label="cancel" fontIcon="cancel" 
                        (click)="isNotSearching = !isNotSearching">
                    </mat-icon>
                </a>
            </div>
            <div class="program-class-header-col">
                <input class="filter-input" type="text" [(ngModel)]="searchQuery.programCode" placeholder="Program code" matInput
                    (keyup)="updateSearch(getEventValue($event))">
            </div>
            <div class="program-class-header-col">
                <input class="filter-input" type="text" [(ngModel)]="searchQuery.programName" placeholder="Program name" matInput
                    (keyup)="updateSearch(getEventValue($event))">
            </div>
            <div class="program-class-header-col">
                <input class="filter-input" type="text" [(ngModel)]="searchQuery.programAddress" placeholder="Program address" matInput
                    (keyup)="updateSearch(getEventValue($event))">
            </div>
            <div class="program-class-header-col">
                <input class="filter-input" type="text" [(ngModel)]="searchQuery.classroomName" placeholder="Classroom name"
                    matInput (keyup)="updateSearch(getEventValue($event))">
            </div>
        </div>
        <div>
            <div *ngIf="(searchResults$ | async)?.length <= 0" class="no-search-results-text">
                No results found. Search using the above filter fields...
            </div>
            <a *ngFor="let result of searchResults$ | async"
                (click)="onSelect(result)">
                <div class="program-class-grid data">
                    <div></div>
                    <div class="program-class-data-col">
                        {{result.program.code}}
                    </div>
                    <div class="program-class-data-col">
                        {{result.program.name}}
                    </div>
                    <div class="program-class-data-col">
                        {{result.program.address}}
                    </div>
                    <div class="program-class-data-col">
                        {{result.id + ' | ' + result.name}}
                    </div>
                </div>
            </a>
        </div>
    </div>
</ng-template>