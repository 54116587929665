<div class="form-container">
    <div class="vaitsn-form-grid-90col">
        <h1 class="form-h1">Child Referrals</h1>
    </div>

    <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
        <div class="vaitsn-form-grid-2col">

            <ng-container *ngIf="(vaUserService.hasRole(VAUserRole.Admin )| async)">
                <div class="vaitsn-form-grid-cell">
                    <mat-label>Coach</mat-label>
                    <app-lookup-coach [permissionFilter]="[VAUserPermission.Consultant]" (selectVAUser$)="selectedVAUser($event)"></app-lookup-coach> 
                    <div *ngIf="isInputValid('User')" class="vaitsn-invalid-container">
                        <div class="alert alert-danger">
                            Please Select a Coach.
                        </div>
                    </div>
                </div> 
            </ng-container>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Date Submitted</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline"  class="vaitsn-date">
                        <input matInput [matDatepicker]="Created" formControlName="Created">
                        <mat-datepicker-toggle matIconSuffix [for]="Created"></mat-datepicker-toggle>
                        <mat-datepicker #Created></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
            
        </div>
 
        <div class="vaitsn-form-grid-3col">

            <div class="vaitsn-form-grid-cell">
                <mat-label>Date of Referral</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-date">
                        <input matInput [matDatepicker]="Referral" formControlName="Referral" placeholder="MM/DD/YYYY">
                        <mat-datepicker-toggle matIconSuffix [for]="Referral"></mat-datepicker-toggle>
                        <mat-datepicker #Referral></mat-datepicker>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Referral')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Date of Referral is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Date of Birth</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-date">
                        <input matInput [matDatepicker]="DOB" formControlName="DOB" placeholder="MM/DD/YYYY">
                        <mat-datepicker-toggle matIconSuffix [for]="DOB"></mat-datepicker-toggle>
                        <mat-datepicker #DOB></mat-datepicker>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('DOB')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Date of Birth is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Child Age (in months)</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-text-field">
                        <input matInput formControlName="AgeMonth" type="number" min="0">
                    </mat-form-field>
                </div>
                
                <div *ngIf="isInputValid('AgeMonth')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Child Age is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Child's City</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-text-field">
                        <input matInput formControlName="City">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('City')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Child City is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Child's Zip Code</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline"  class="vaitsn-text-field">
                        <input matInput formControlName="ZipCode" minlength="5" maxlength="5" onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('ZipCode')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Child Zip Code is Required. 
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
            </div>

            <div class="vaitsn-form-grid-cell">
            </div>
        </div>

        <div class="vaitsn-form-grid-3col">
            
            <div class="vaitsn-form-grid-cell">
                <mat-label style="width:50vw">Language(s) Spoken at Home</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field class="example-full-width" appearance="outline" class="vaitsn-text-field">
                        <mat-select formControlName="HomeLanguage" multiple>
                            <mat-option *ngFor="let language of languages" [value]="language.languageName">{{ language.languageName }}</mat-option>
                          </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('HomeLanguage')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Languages Spoken at Home are Required. Please make a selection.
                        If "Other" option is selected, the "Other" field is required to have data
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell" *ngIf="formGroup.controls['HomeLanguage'].value.includes('Other')">
                <mat-label style="width:50vw">Other Language(s)</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field class="example-full-width" appearance="outline" subscriptSizing="dynamic">
                        <textarea matInput formControlName="HomeLanguage2"></textarea>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('HomeLanguage2')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Optional Language was select, need a language to be entered.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Interpreter Needed</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline"  class="vaitsn-text-field">
                        <mat-select formControlName="NeedsInterpreter">
                            <mat-option *ngFor="let yesno of YesNos" [value]="yesno">
                                {{ yesno.YesNoCD }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('NeedsInterpreter')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Interpreter Needed is Required. Please make a selection.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Foster Care</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-text-field">
                        <mat-select formControlName="NeedsFosterCare">
                            <mat-option *ngFor="let yesno of YesNos" [value]="yesno">
                                {{ yesno.YesNoCD }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('NeedsFosterCare')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Is the Child in Foster Care? Please make a selection.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Intake Status</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline"  class="vaitsn-text-field">
                        <mat-select formControlName="StatusId">
                            <mat-option *ngFor="let intakestatus of childStatus"
                                [value]="intakestatus.id">
                                {{ intakestatus.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('StatusId')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Intake Status is Required. Please make a selection.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label style="width:50vw">Child's Race</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field class="example-full-width" appearance="outline" subscriptSizing="dynamic">
                        <textarea matInput formControlName="Race"></textarea>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Race')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Child Race is Required. Please enter a race(s).
                    </div>
                </div>
            </div>
 
            <div class="vaitsn-form-grid-cell">
                <mat-label>Hispanic </mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-text-field">
                        <mat-select formControlName="IsHispanic">
                            <mat-option *ngFor="let yesnonull of YesNoNulls" [value]="yesnonull">
                                {{ yesnonull.YesNoNullCD }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('IsHispanic')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Is the Child Hispanic? Please make a selection.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Child's Gender </mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-text-field">
                        <mat-select formControlName="Gender">
                            <mat-option *ngFor="let childgender of ChildGenders" [value]="childgender.ChildGenderCD">
                                {{ childgender.ChildGenderCD }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('Gender')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Child's Gender is Required.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell" *ngIf="!formGroup.controls['HomeLanguage'].value.includes('Other')">
            </div>

            <div class="vaitsn-form-grid-cell">
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>IFSP </mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-text-field">
                        <mat-select formControlName="IsIFSP" (selectionChange)="getDisabledValue($event)">
                            <mat-option *ngFor="let yesno of YesNos" [value]="yesno">
                                {{ yesno.YesNoCD }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('IsIFSP')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        IFSP is Required.
                        If "Yes" option is selected, then please state the services in the next field.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label style="width:50vw">If yes, Services Recorded?</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <textarea matInput formControlName="IFSPSvc"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label style="width:50vw">Diagnosis</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <textarea matInput formControlName="Diagnosis"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label style="width:50vw">Medications</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <textarea matInput formControlName="Medications"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label style="width:50vw">Allergies</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <textarea matInput formControlName="Allergies"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label style="width:50vw">Other Agencies Involved</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <textarea matInput formControlName="OtherAgencies"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Expulsion Risk </mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-text-field">
                        <mat-select formControlName="HasExpulsionRisk">
                            <mat-option *ngFor="let yesnonull of YesNoNulls" [value]="yesnonull">
                                {{ yesnonull.YesNoNullCD }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('HasExpulsionRisk')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Is the Child at Risk of Expulsion?
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Receives Subsidy </mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-text-field">
                        <mat-select formControlName="ReceivesSubsidy">
                            <mat-option *ngFor="let yesnonull of YesNoNulls" [value]="yesnonull">
                                {{ yesnonull.YesNoNullCD }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('ReceivesSubsidy')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Are Subsidies Received?
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Date Started Attending</mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-date">
                        <input matInput [matDatepicker]="StartedAttending" formControlName="StartedAttending" placeholder="MM/DD/YYYY">
                        <mat-datepicker-toggle matIconSuffix [for]="StartedAttending"></mat-datepicker-toggle>
                        <mat-datepicker #StartedAttending></mat-datepicker>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('StartedAttending')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        Start Date of Child?
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Number of Children in Room </mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field appearance="outline" class="vaitsn-text-field">
                        <input matInput type="number"  min="0" formControlName="ClassStudentCount">
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('ClassStudentCount')" class="vaitsn-invalid-container">
                    <div class="alert alert-danger">
                        How Many Children in The Room?
                    </div>
                </div>
            </div>
        </div>

        <div class="vaitsn-form-grid-cell">
            <div class="vaitsn-form-grid">
                <div class="vaitsn-form-grid-cell">
                    <mat-label>Program/ Classroom </mat-label>
                    <div class="vaitsn-form-control">
                        <app-lookup-program-classroom (select$)="selectedProgram($event)"></app-lookup-program-classroom>
                    </div>
           
                    <div *ngIf="isInputValid('ClassroomId')" class="vaitsn-invalid-container">
                        <div class="alert alert-danger">
                            Program and Classroom are Required. Click the magnifying glass to search.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="vaitsn-form-grid-3col">

            <mat-label><u>REASON(S) FOR REFERRAL </u></mat-label>

            <div class="vaitsn-form-grid-cell">
            </div>

            <div class="vaitsn-form-grid-cell">
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Adaptive Behavior </mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="HasAdaptiveBehavior">
                            <mat-option *ngFor="let yesnonull of YesNoNulls" [value]="yesnonull">
                                {{ yesnonull.YesNoNullCD }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('HasAdaptiveBehavior')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Question Requires an Answer, If Not Applicable Select N/A.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Affect </mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="HasAffect">
                            <mat-option *ngFor="let yesnonull of YesNoNulls" [value]="yesnonull">
                                {{ yesnonull.YesNoNullCD }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('HasAffect')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Question Requires an Answer, If Not Applicable Select N/A.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Aggression </mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="HasAggression">
                            <mat-option *ngFor="let yesnonull of YesNoNulls" [value]="yesnonull">
                                {{ yesnonull.YesNoNullCD }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('HasAggression')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Question Requires an Answer, If Not Applicable Select N/A.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Attachment </mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="HasAttachment">
                            <mat-option *ngFor="let yesnonull of YesNoNulls" [value]="yesnonull">
                                {{ yesnonull.YesNoNullCD }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('HasAttachment')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Question Requires an Answer, If Not Applicable Select N/A.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Autonomy </mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="HasAutonomy">
                            <mat-option *ngFor="let yesnonull of YesNoNulls" [value]="yesnonull">
                                {{ yesnonull.YesNoNullCD }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('HasAutonomy')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Question Requires an Answer, If Not Applicable Select N/A.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Compliance </mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="HasCompliance">
                            <mat-option *ngFor="let yesnonull of YesNoNulls" [value]="yesnonull">
                                {{ yesnonull.YesNoNullCD }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('HasCompliance')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Question Requires an Answer, If Not Applicable Select N/A.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Interaction </mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="HasInteraction">
                            <mat-option *ngFor="let yesnonull of YesNoNulls" [value]="yesnonull">
                                {{ yesnonull.YesNoNullCD }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('HasInteraction')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Question Requires an Answer, If Not Applicable Select N/A.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Self Regulation </mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="HasSelfRegulation">
                            <mat-option *ngFor="let yesnonull of YesNoNulls" [value]="yesnonull">
                                {{ yesnonull.YesNoNullCD }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('HasSelfRegulation')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Question Requires an Answer, If Not Applicable Select N/A.
                    </div>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Self Communication </mat-label>
                <div class="vaitsn-form-control" class="vaitsn-text-field">
                    <mat-form-field appearance="outline">
                        <mat-select formControlName="HasSocialCommunication">
                            <mat-option *ngFor="let yesnonull of YesNoNulls" [value]="yesnonull">
                                {{ yesnonull.YesNoNullCD }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div *ngIf="isInputValid('HasSocialCommunication')" class="vaitsn-invalid-container-small">
                    <div class="alert alert-danger">
                        Question Requires an Answer, If Not Applicable Select N/A.
                    </div>
                </div>
            </div>

        </div>


        <div class="vaitsn-form-grid-3col">

            <div class="vaitsn-form-grid-cell">
                <mat-label>Referral Notes </mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <textarea matInput formControlName="ReferralNotes"></textarea>
                    </mat-form-field>
                </div>
            </div>


            <div class="vaitsn-form-grid-cell">
                <mat-label>Referral Agency </mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <textarea matInput formControlName="ReferralAgency"></textarea>
                    </mat-form-field>
                </div>
            </div>

            <div class="vaitsn-form-grid-cell">
                <mat-label>Other Source </mat-label>
                <div class="vaitsn-form-control">
                    <mat-form-field class="example-full-width" appearance="outline">
                        <textarea matInput formControlName="OtherReferralSource"></textarea>
                    </mat-form-field>
                </div>
            </div>
        </div> 

        <div class="vaitsn-form-submit-container-size">
            <vaitsn-button size="custom" customWidth="100%" customHeight="30px"  mat-flat-button class="vaitsn-btn-primary" (click)="onSubmit()" role="submit">Submit</vaitsn-button>
        </div>
    </form>
</div>

