import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import { DxDataGridComponent } from 'devextreme-angular';
const { DateTime } = require("luxon");
import ODataFilterBuilder from 'odata-filter-builder';
import { Subject, filter, map, takeUntil, tap } from 'rxjs';

import { IRegion, IVAUser } from '../../../models';
import { VAUserRole, VAUserService } from '../../../services/va-user.service';
import { DXODataService } from '../../../services/dx-odata.service';
import { RegionService } from '../../../services/region.service';
import { TravelService } from '../../../services/travel.service';

@Component({
  selector: 'app-travel-vouchers',
  templateUrl: './travel-vouchers.component.html',
  styleUrls: ['./travel-vouchers.component.scss']
})
export class TravelVouchersComponent implements OnInit, OnDestroy {
  VAUserRole = VAUserRole;
  @ViewChild('travelVoucherDxGrid', { static: false }) travelVoucherDxGrid: DxDataGridComponent;
  public travelVoucherReportDataSource: DataSource = null;
  private _subscribedSubjects$ = new Subject<boolean>();

  public currentVAUser: IVAUser = null;
  public isDataLoading: boolean = true;

  public regions: Array<IRegion> = [];
  public filteredRegions: Array<IRegion> = [];
  public selectedRegion: IRegion = null;

  constructor(public dxOdataService: DXODataService, private travelService: TravelService, private router: Router, public vaUserService: VAUserService, private regionService: RegionService) { }

  ngOnInit() {
    this.getCurrentAssociatedVAUser();
  }

  getCurrentAssociatedVAUser() {
    this.vaUserService.currentAssociatedVAUser$.pipe(
      filter(u => u !== null),
      takeUntil(this._subscribedSubjects$),
      map((vaUser) => {
        this.currentVAUser = vaUser;
        this.travelVoucherReportDataSource = new DataSource({
          store: this.dxOdataService.context.TravelVouchersReport,
          filter: this.getTravelVoucherFilters(vaUser)
        });
        this.getRegions();
      })).subscribe();
  }

  getTravelVoucherFilters(vaUser: IVAUser) {
    const filters = new ODataFilterBuilder('and')
      .eq('deleted', null);

    if (this.vaUserService.userHasRole(vaUser, VAUserRole.Supervisor) || this.vaUserService.userHasRole(vaUser, VAUserRole.Admin)) {
      if (!this.selectedRegion) {
        filters.eq('homeRegionId', (this.currentVAUser) ? this.currentVAUser.regionId : 0);
      } else {
        filters.eq('homeRegionId', this.selectedRegion.id);
      }
    } else {
      filters.eq('username', this.currentVAUser?.username);
    }

    // date range for travel:
    // if first few days of month, include travel starting from first of last month
    // else only show travel starting from first of current month
    let today = new Date();

    let begin = new Date();
    if (begin.getUTCDate() <= 5) begin.setMonth(today.getUTCMonth() - 1);
    begin.setDate(1);
    begin.setHours(0, 0, 0, 0);
    
    let end = new Date(new Date().setDate(today.getUTCDate() + 1));
    end.setHours(0, 0, 0, 0);
    
    filters.and('traveled ge ' + DateTime.fromJSDate(begin).toFormat('yyyy-MM-dd'));
    filters.and('traveled lt ' + DateTime.fromJSDate(end).toFormat('yyyy-MM-dd'));

    return filters.toString();
  }

  public getEventValue(event: Event): string {
    return (event.target as HTMLInputElement).value;
  }

  public newTravelVoucher() {
    this.router.navigate(['/app/travel/voucher/', 0]);
  }

  public filterRegions(searchString: string) {
    this.filteredRegions = this.regions.filter(region => region.name.includes(searchString) || region.code.includes(searchString))
  }

  public regionDisplay = (region: IRegion): string => region?.name;

  private getRegions() {
    this.regionService.get().pipe(
      filter(r => r !== null),
      takeUntil(this._subscribedSubjects$),
      map((regions) => {
        this.regions = regions;
        this.filteredRegions = regions;
        if (this.currentVAUser?.regionId > 0) {
          this.selectedRegion = this.regions.find(r => r.id == this.currentVAUser.regionId);
        }
      }), tap((_) => this.isDataLoading = false)).subscribe();
  }

  onRegionChange(event: any) {
    this.travelVoucherReportDataSource.filter(this.getTravelVoucherFilters(this.currentVAUser));
    this.travelVoucherDxGrid.instance.refresh();
  }

  public editRecord(groupRow: any) {
    this.router.navigate(['/app/travel/voucher/', groupRow.key]);
  }

  public deleteRecord(groupRow: any) {
    this.travelService.delete(groupRow.key).pipe(tap((_) => this.travelVoucherReportDataSource.reload())).subscribe();
  }

  ngOnDestroy() {
    this._subscribedSubjects$.next(true);
    this._subscribedSubjects$.complete();
  }
}
