import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import ODataFilterBuilder from 'odata-filter-builder';
import { Observable, Subject, catchError, combineLatest, map, of, takeUntil, tap } from 'rxjs';

import { EventObj, GUID, IEventDetailTopic, IEventDetails, IParamObj, IVAUser, VAUser } from '../../../models';
import { EventType } from '../../../vaitsn-constants';
import { EventService } from '../../../services/event.service';
import { VAUserPermission, VAUserService } from '../../../services/va-user.service';

/**
 * Custom cross-validator for entire form.
 * Require at least one checkbox to be selected.
 * @param control `this.eventCoachingForm` (FormGroup)
 * @returns `'checkedBoxRequired'` validation error if no checkboxes selected. Else, null (FormGroup is valid).
 */
export const checkedBoxRequiredValidator: ValidatorFn = (
  control: AbstractControl, // this.eventCoachingForm - FormGroup
): ValidationErrors | null => {
  let formGroup = control as FormGroup;

  let someBoxChecked = Object.keys(formGroup?.controls)?.some(key => {
    const formControl = formGroup?.get(key);
    return key === 'otopic1' ? false : (Boolean(formControl?.value) === true);
  });

  return someBoxChecked ? null : { checkedBoxRequired: true };
};

/**
 * @todo Prevent editing event type and coach during edit
 * @todo clear topic values if change event type or coach during create
 */
@Component({
  selector: 'app-event-coaching-details',
  templateUrl: './event-coaching.component.html',
  styleUrls: ['./event-coaching.component.scss']
})
export class EventCoachingComponent implements OnInit, OnDestroy {
  public currentEventId: number = 0;
  public isFormLoading: boolean = true;
  public eventCoachingForm: FormGroup = null;
  private _subscribedSubjects$ = new Subject<boolean>();
  public eventTopics: IEventDetailTopic[];
  private _EventType = EventType;

  constructor(
    private router: Router,
    private location: Location,
    private activatedRoute: ActivatedRoute,
    private eventService: EventService,
    private vaUserService: VAUserService,
  ) { }

  /**
   * Load required data from services
   * Determine which topics should be shown
   * Init dynamic form
   */
  public ngOnInit() {
    this.currentEventId = +this.activatedRoute.snapshot.paramMap.get('eventId');

    this.getRequiredData().subscribe({
      next: data => {
        const mainPres = data[0] as IVAUser;
        if (!mainPres) {
          return this.handlePageLoadError('Coach data not found.');
        }

        const eventObj = this.eventService.$currentEvent.value;

        this.filterEventDetailTopics(eventObj, mainPres);
        this.initForm();

        if (this.currentEventId > 0 || this.eventService.$currentEvent.value.event.regionId) {
          this.patchFormFromCurrentEvent();
        }

        this.isFormLoading = false;
      },
      error: err => {
        console.error(err);
        this.handlePageLoadError('Error gathering required data');
      }
    });
  }

  /**
   * Notify user of error
   * Return to previous step
   * @param err 
   */
  private handlePageLoadError(err?: string) {
    err && console.error(err);
    this.router.navigate(['app/event/details/', this.currentEventId]);
  }

  /**
   * Get required data for form from services
   * 1: Get list of all VAUsers. Used to populate existing Additional Presenters data / select new ones
   * 2: Get current VAUser
   * @returns 
   * @todo if current user is needed (TBD), maybe use take1 and forkjoin instead; else can make single observable
   */
  private getRequiredData = () => {
    let sources = [];
    const eventObj = this.eventService.$currentEvent.value;

    sources.push(this.getMainPresenterVAUser(new GUID(eventObj.event.mainPresenterId)));

    if (this.currentEventId > 0 && !this.eventService.$currentEvent.value?.event?.regionId) {
      sources.push(this.eventService.refreshCurrentEvent(this.currentEventId).pipe(takeUntil(this._subscribedSubjects$)));
    }
    
    return combineLatest(sources);
  }
 
  /**
   * 
   * @param id 
   * @returns VAUser if found, undefined if request error or not found
   * @todo get vauser could get by Id instead of filtering. this would be better to avoid error if result is empty list
   */
  private getMainPresenterVAUser(mainId: GUID): Observable<IVAUser> {
    const builder = ODataFilterBuilder('and').eq('Id', mainId);
    const params = { 
      $filter: builder.toString(),
      $expand: 'VAUserRoles($expand=Role), VAUserPermissions($expand=Permission)',
      $top: 1
    } as IParamObj;
    return this.vaUserService.get(params).pipe(
      catchError(err => {
        console.error(err);
        return of(undefined);
      }),
      map((res) => res.pop())
    );
  }

  /**
   * Determine which topics to show based on event type (coaching/child coaching) + main presenter's coach type
   * @returns 
   */
  private filterEventDetailTopics = (eventObj: EventObj, mainPres: IVAUser) => {
    let coachIsITBC = this.vaUserService.userHasPermission(mainPres, VAUserPermission.Consultant);
    let coachIsITS = this.vaUserService.userHasPermission(mainPres, VAUserPermission.Specialist);
    let eventTypeId = eventObj.eventType.id;

    this.eventTopics = this.eventService.EVENT_DETAIL_TOPICS.filter(topic =>
      (topic.showIfITBC === coachIsITBC || topic.showIfITS === coachIsITS)
      && (
        // Show if TA
        ( eventTypeId === this._EventType.TechnicalAssistance && topic.showIfTA === true )
        // Show if Child Coaching
        || ( eventTypeId === this._EventType.ChildCoaching 
           && ( topic.showIfChildCoaching === true 
              || ( topic.showIfChildCoachingITBCOnly === true && coachIsITBC ) ) )
        // Show if other Coachings
        || ( eventTypeId !== this._EventType.TechnicalAssistance && eventTypeId !== this._EventType.ChildCoaching
            && topic.showIfCoaching === true )
      )
    );

    this.eventTopics.sort((a, b) =>{
      if(a.displayName.toLocaleLowerCase() < b.displayName.toLocaleLowerCase()) return -1;
      else if (a.displayName.toLocaleLowerCase() > b.displayName.toLocaleLowerCase()) return 1;
      else return 0;
    });
  }

  /**
   * Init form with default values
   * Then patch form if Editing an event
   * @todo move get full event details refreshCurrentEvent() to required data gathering step
   */
  private initForm = () => {
    const group: any = {};
    
    // Add static non-checkbox fields to form group
    group['otopic1'] = new FormControl<string|null>(null);

    this.eventTopics.forEach(topic => {
      group[topic.entityName] = new FormControl(false);
    });

    this.eventCoachingForm = new FormGroup(group, { validators: checkedBoxRequiredValidator });
  }

  /**
   * For edit, load event data into form
   */
  private patchFormFromCurrentEvent() {
    const eventObj = this.eventService.$currentEvent.value;
    const patch: Partial<IEventDetails> = {};

    for (const key of Object.keys(this.eventCoachingForm.controls)) {
      patch[key] = eventObj.eventDetail[key];
    }

    this.eventCoachingForm.patchValue(patch);
  }

  public onSubmit() {
    this.eventCoachingForm.markAllAsTouched();
    if (!this.eventCoachingForm.valid) {
      console.warn(this.eventCoachingForm.value);
      if (this.eventCoachingForm.errors?.['checkedBoxRequired']) {
        console.warn('At least one checkbox must be selected.');
      }
      return;
    }

    this.saveFormValuesToCurrentEvent();
    this.router.navigate(['app/event/participants/', this.currentEventId]);
  }

  public onBackClicked(): void {
    if (this.eventCoachingForm.valid) {
      this.saveFormValuesToCurrentEvent();
    }
    else if (this.eventCoachingForm.errors?.['checkedBoxRequired']) {
      console.warn('At least one checkbox must be selected.');
    }
    this.router.navigate(['app/event/details/', this.currentEventId]);
  }

  /**
   * On submit, assign latest form values to service eventObj
   */
  private saveFormValuesToCurrentEvent() {
    let eventObj = this.eventService.$currentEvent.value;

    for (const [key, control] of Object.entries(this.eventCoachingForm.controls)) {
      eventObj.eventDetail[key] = control.value;
    }

    this.eventService.$currentEvent.next(eventObj);
  }

  public ngOnDestroy() {
    this._subscribedSubjects$.next(true);
    this._subscribedSubjects$.complete();
  }

}
